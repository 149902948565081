import React from 'react';
import img1 from './infrastructure/images/m.jpg';

function App() {
  return (

    <div className="bg-gradient">

      <div className="container mx-auto">

        <section className="flex flex-wrap lg:flex-nowrap justify-center h-screen relative">
          <div className="my-auto font-poppins text-gray-800 text-center lg:text-left">
            <h1 className="font-medium text-5xl py-3">The new website is <br /> Coming....</h1>
            <p className="lg:w-5/6">we have done all the technical improvements and will back very soon. Thank you for your patience!</p>

            <div className="bg-gray-200 p-3 px-5 sm:w-96 rounded-md my-8 mx-auto lg:mx-0 flex">
              <input type="text" className="flex-gro rounded-xl mr-5" placeholder="Your email Address" />
              <button className="text-gray-600 hover:text-red-400">Notify Me</button>
            </div>
          </div>

          <div className="my-auto text-center w-3/4 lg:w-2/5">
            <img className="rounded-full image1 animate-moveY" src={img1} alt="image1" />
          </div>

        </section>


      </div>
    </div>
  );
}

export default App;
